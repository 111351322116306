import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, CardHeader, Button, FormGroup, Label, Input } from 'reactstrap';
import Enums from '../../../constants/enums';
import BaseAPI from '../../../helpers/base-api';

import { formatDate } from '#helpers/displayUtils.js';

const baseAPI = new BaseAPI();

const UserEdit = ({ userData, onSave }) => {
    const history = useHistory(); // Hook to enable programmatic navigation
    const displayValue = (value) => value || 'N/A';
    const [tierMap, setTierMap] = useState({});

    const [formData, setFormData] = useState({
        // name: userData.name || '',
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        address: userData.address || '',
        city: userData.city || '',
        state: userData.state || '',
        zip: userData.zip || '',
        birthDay: userData.birthDay || '',
        userStatus: userData.status || '',
        boostLevel: userData.boostLevel || '',
    });

    // Add this useEffect hook
    useEffect(() => {
        setFormData({
            // name: userData.name || '',
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            address: userData.address || '',
            city: userData.city || '',
            state: userData.state || '',
            zip: userData.zip || '',
            birthDay: userData.birthDay || '',
            userStatus: userData.status || '',
            boostLevel: userData.boostLevel || '',
        });
    }, [userData]);

    useEffect(() => {
        fetchBoostLevels();
    }, []);

    const fetchBoostLevels = async () => {
        try {
            const response = await baseAPI.get('boostLevels');
            const fetchedData = response.data;
            const newTierMap = fetchedData.reduce((map, item) => {
                map[item.name] = item.label;
                return map;
            }, {});

            setTierMap(newTierMap);
        } catch (error) {
            console.error('Failed to fetch boost levels:', error);
        }
    };

    const handleSave = () => {
        onSave(formData);
    };
    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const goBack = () => {
        history.push(`/users/view/${userData.id}`);
    };

    return (
        <React.Fragment>
            <Button color="primary" onClick={goBack} style={{ marginBottom: '20px' }}>
                Back to the {userData.email} Details
            </Button>
            <Container className="user-details">
                <Row className="mb-4">
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md={4}>
                                        <strong>Email:</strong>
                                        <br /> {displayValue(userData.email)}
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="firstName">
                                                <strong>First Name:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                value={formData.firstName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="lastName">
                                                <strong>Last Name:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                value={formData.lastName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="address">
                                                <strong>Street:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="address"
                                                id="address"
                                                value={formData.address}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="city">
                                                <strong>City:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="city"
                                                id="city"
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="state">
                                                <strong>State:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="state"
                                                id="state"
                                                value={formData.state}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="zip">
                                                <strong>Zip:</strong>
                                            </Label>
                                            <Input
                                                type="text"
                                                name="zip"
                                                id="zip"
                                                value={formData.zip}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="birthDay">
                                                <strong>DOB:</strong>
                                            </Label>
                                            <Input
                                                type="date"
                                                name="birthDay"
                                                id="birthDay"
                                                value={formData.birthDay}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="status">
                                                <strong>Status:</strong>{' '}
                                            </Label>

                                            <Input
                                                type="select"
                                                name="userStatus"
                                                id="userStatus"
                                                onChange={handleInputChange}
                                                value={formData.userStatus}
                                                style={{ textTransform: 'uppercase', marginLeft: `1em` }}>
                                                {Object.values(Enums.UserStatus).map((item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item}
                                                        style={{ textTransform: 'uppercase' }}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="user-tier">
                                                <strong>Tier:</strong>
                                            </Label>

                                            <Input
                                                type="select"
                                                name="boostLevel"
                                                id="boostLevel"
                                                onChange={handleInputChange}
                                                value={formData.boostLevel}
                                                style={{ marginLeft: `1em` }}>
                                                {Object.keys(tierMap).map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {tierMap[item]}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>

                                        <Button color="primary" onClick={handleSave}>
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default UserEdit;
